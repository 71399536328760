import React from 'react';
import cls from './information.module.scss';
import { Card, Container } from '@material-ui/core';
import HelmetComponent from '../../components/HelmetComponent';
import { useTranslation } from 'react-i18next';
import img1 from '../../img/first/step001.png';
import img2 from '../../img/first/step002.png';
import img3 from '../../img/first/step003.png';
import img4 from '../../img/first/step004.png';
import InformBlock from '../../components/InformBlock';
import BannerBlock from '../BannerBlock';
import Company from '../../components/Company';
import Certificates from '../../components/Certificates';
import Login from '../../pages/Login';
import Maps from '../../components/Maps';

const InformationUser = ({language}) => {
    const { t } = useTranslation();
    return (
        <main className={cls.information_container}>
            <HelmetComponent titlePage={t('helmet.home.title',{ lng: language })} description={t('helmet.home.description',{ lng: language })} title={t('helmet.home.title',{ lng: language })} keywords={t('helmet.home.keywords',{ lng: language })}/>
            <BannerBlock />
            <div style={{ position: 'relative', background: '#eaeaea' }}>
                <Container component="main" maxWidth="lg">
                    <InformBlock language={language}/>
                    {/* </Container> */}
                    <Card className={cls.information_block_card}>
                        <h3>{t('InformationUser.h3',{ lng: language })}</h3>
                        <div className={cls.wrapper_block}>
                            <div className={cls.cur_block}>
                                <img src={img1} alt='img1' />
                                <div className={cls.line} />
                                <h4>
                                    {t('InformationUser.leave',{ lng: language })}
                                </h4>
                            </div>
                            <div className={cls.cur_block}>
                                <img src={img2} alt='img2' />
                                <div className={cls.line} />
                                <h4>
                                    {t('InformationUser.wait',{ lng: language })}
                                </h4>
                            </div>
                            <div className={cls.cur_block}>
                                <img src={img3} alt='img3' />
                                <div className={cls.line} />
                                <h4>
                                    {t('InformationUser.approve',{ lng: language })}
                                </h4>
                            </div>
                            <div className={cls.cur_block}>
                                <img src={img4} alt='img4' />
                                <div className={cls.line} />
                                <h4>
                                    {t('InformationUser.get',{ lng: language })}
                                </h4>
                            </div>
                        </div>
                        <Login language={language}/>
                    </Card>
                    <Company language={language}/>
                </Container>
                <Certificates language={language}/>
                <Container component="main" maxWidth="lg">
                    <Maps />
                </Container>
            </div>
        </main>)
};

export default InformationUser;