import React, {
  useMemo, useState,
} from 'react';
import cls from './App.module.scss';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import Page404 from './pages/Page404';
import 'react-toastify/dist/ReactToastify.css';
import { useTransition, animated } from 'react-spring';
import Footer from './pages/Footer';
import Header from './pages/Header';
import InformationUser from './pages/InformationUser';
import Contacts from './pages/Contacts';
import PreHeader from './pages/PreHeader';
import Translations from './pages/Translations';
import Consulting from './pages/Consulting';
import Mail from './pages/Mail';
import Relocation from './pages/Relocation';
import Confession from './pages/Confession';
import jsonPackage from '../package.json';
import { useTranslation } from 'react-i18next';


const App = () => {
  const location = useLocation();
  const localCheckVersion = localStorage.getItem('version');
  useMemo(() => {
    // сброс локал хранилища при смене версии проекта
    if (localCheckVersion && localCheckVersion !== jsonPackage.version) {
      localStorage.clear();
    } else {
      localStorage.setItem('version', jsonPackage.version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const transitions = useTransition(location, location => location.pathname, {
    from: {
      opacity: 0,
      position: 'absolute',
      width: '90%',
      left: '50vw',
      minHeight: "calc(100vh - 292px - 125px - 187px)"
    },
    enter: {
      position: 'inherit',
      opacity: 1,
      width: '100%',
      left: '0vw'
    },
    leave: {
      position: 'absolute',
      opacity: 0,
      width: '90%',
      left: "-50vw"
    },
    unique: false,
    reset: true,
  });
  const [buttonBurger, setButtonBurger] = useState(false);
  const { i18n: { language } } = useTranslation();
  return (
    <div className={`${cls.App}`}>
      <PreHeader buttonBurger={buttonBurger}/>
      <Header buttonBurger={buttonBurger} setButtonBurger={setButtonBurger} />
      <div className={cls.home_content}>
        {transitions.map(({ item, props: transition, key }) => (
          <animated.div
            id='animated'
            key={key}
            style={transition}
          >
            <Switch location={item}>
              <Route exact path='/' render={() => <Redirect to={`/${language}`} />} />
              {['ru', 'en', 'md'].map((el, index) => <Route key={index} path={`/${el}`} render={() =>
                <Switch>
                  <Route exact path={`/${el}`} render={() => <InformationUser language={el} />} />
                  <Route exact path={`/${el}/translations`} render={() => <Translations language={el} />} />
                  <Route exact path={`/${el}/confession`} render={() => <Confession language={el} />} />
                  <Route exact path={`/${el}/consulting`} render={() => <Consulting language={el} />} />
                  <Route exact path={`/${el}/mail`} render={() => <Mail language={el} />} />
                  <Route exact path={`/${el}/relocation`} render={() => <Relocation language={el} />} />
                  <Route exact path={`/${el}/contacts`} render={() => <Contacts language={el} />} />
                  <Route component={Page404} />
                </Switch>} />)}
              <Route component={Page404} />
            </Switch>
          </animated.div>))}
        <Footer />
      </div>
    </div>
  );
}

export default App;
