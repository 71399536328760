import React from 'react';
import cls from './information.module.scss';
import { Card, Container } from '@material-ui/core';
import HelmetComponent from '../../components/HelmetComponent';
import { useTranslation } from 'react-i18next';
import CompanyConfession from '../../components/CompanyConfession';

const Confession = ({language}) => {
    const { t } = useTranslation();
    return (
        <main className={cls.information_container}>
            <HelmetComponent titlePage={t('helmet.сonfession.title',{ lng: language })} description={t('helmet.сonfession.description',{ lng: language })} title={t('helmet.сonfession.title',{ lng: language })} keywords={t('helmet.сonfession.keywords',{ lng: language })} />
            <div style={{ position: 'relative' }}>
                <Container component="main" maxWidth="lg">
                    {/* </Container> */}
                    <Card className={cls.information_block_card}>
                        <CompanyConfession language={language}/>
                    </Card>
                </Container>
            </div>
        </main>)
};

export default Confession;