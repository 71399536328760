import React from 'react';
import cls from './preHeader.module.scss';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useTranslation } from 'react-i18next';
import lngUrl from '../../helpers/lngUrl';

const PreHeader = ({ buttonBurger }) => {
    const { t } = useTranslation();
    return (<div className={`${cls.wrapper_pre_header} ${buttonBurger ? cls.displayNone : ''}`}>
        <div className={cls.text}>{t('PreHeader.questions', { lng: lngUrl() })}</div>
        <a href="tel:+373 671 40415"> <PhoneIcon />+373 671 40415</a>
        <a href="mailto:bdtinterdoc@gmail.com"><MailOutlineIcon />bdtinterdoc@gmail.com</a>
    </div>)
}

export default PreHeader;