import React from 'react';
import cls from './certificates.module.scss';
// import { School, Mic } from '@material-ui/icons';
import stamp from '../../img/stamp.png';
import price from '../../img/price.jpg';
import { useTranslation } from 'react-i18next';

const Certificates = ({language}) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('Certificates.h2',{ lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <img src={price} alt="price" />
            </div>
            <div className={cls.img_block}>
                <img src={stamp} alt="stamp" />
            </div>
        </div>
    )
}
export default Certificates;