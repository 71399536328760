/* eslint-disable react-hooks/rules-of-hooks */
// import { useState } from "react";

const swipeHandler = (setButtonBurger) => {

    // const [xDown, setXDown] = useState(null);
    // const [yDown, setYDown] = useState(null);


    let xDown = null;                                                        
    let yDown = null;

    const getTouches = (evt) => {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                setButtonBurger(true)
            } else {
                setButtonBurger(false)
            }
        }
        xDown = null;
        yDown = null;
    };
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
}

export default swipeHandler;