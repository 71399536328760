import React, { useRef, useEffect } from 'react';
import cls from './bannerBlock.module.scss';
import home from '../../img/home2.jpg';

const BannerBlock = () => {
    const refImg = useRef();
    const scrollFunc = () => {
        let s = window.pageYOffset;
        s /= 3;
        if(refImg.current){
            refImg.current.style.top = `${s}px`;
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollFunc);
    }, []);
    return (
        <div ref={refImg} className={cls.contacts_img_block}>
            <img src={home} alt='карта' />
        </div>
    )
}

export default BannerBlock;