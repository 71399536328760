import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';

const CompanyTranslations = ({language}) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('pages.CompanyTranslations.h2',{ lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <li>
                            {t('pages.CompanyTranslations.description1',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description2',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description3',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description4',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description5',{ lng: language })}
                        </li>
                        <div>
                            {t('pages.CompanyTranslations.description6',{ lng: language })}
                        </div>
                        <h2>
                            {t('pages.CompanyTranslations.description7',{ lng: language })}
                        </h2>
                        <div>
                            {t('pages.CompanyTranslations.description8',{ lng: language })}
                        </div>
                        <li>
                            {t('pages.CompanyTranslations.description9',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description10',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description11',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description12',{ lng: language })}
                        </li>
                        <li>
                            {t('pages.CompanyTranslations.description13',{ lng: language })}
                        </li>
                        <h2>
                            {t('pages.CompanyTranslations.description14',{ lng: language })}
                        </h2>
                        <div>
                            {t('pages.CompanyTranslations.description15',{ lng: language })}
                        </div>
                        <h4>
                            {t('pages.CompanyTranslations.description16',{ lng: language })}
                            <span>{t('pages.CompanyTranslations.description17',{ lng: language })}</span>
                        </h4>
                        <h4>
                            {t('pages.CompanyTranslations.description18',{ lng: language })}
                            <span>
                                {t('pages.CompanyTranslations.description19',{ lng: language })}
                            </span>
                        </h4>
                        <h4>
                            {t('pages.CompanyTranslations.description20',{ lng: language })}
                            <span>
                                {t('pages.CompanyTranslations.description21',{ lng: language })}
                            </span>
                        </h4>
                        <h4>
                            {t('pages.CompanyTranslations.description22',{ lng: language })}
                            <span>
                                {t('pages.CompanyTranslations.description23',{ lng: language })}
                            </span>
                        </h4>
                        <h4>
                            {t('pages.CompanyTranslations.description24',{ lng: language })}
                        </h4>
                        <h4>
                            {t('pages.CompanyTranslations.description25',{ lng: language })}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyTranslations;