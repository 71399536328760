const Language = () => {
  let language = 'ru';
  const urlPart = window.location.pathname.substr(1, 2);
  const lngStorage = localStorage.getItem('language');
  if (lngStorage && (lngStorage === 'en' || lngStorage === 'ru' || lngStorage === 'md')) {
    language = lngStorage;
  } else if (urlPart && (urlPart === 'en' || urlPart === 'ru' || urlPart === 'md')) {
    language = urlPart;
    if(!lngStorage){
      localStorage.setItem('language', language);
    }
  }
  else {
    localStorage.setItem('language', language);
  };
  return language;
};

export default Language;