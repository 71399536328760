const validate = (values, t /* only available when using withFormik */) => {
  const errors = {};
  if (!values.number) {
    errors.number = t('Login.validate.number');
  }

  if (!values.service) {
    errors.service = t('Login.validate.service');
  }
  if (!values.name) {
    errors.name = t('Login.validate.name');
  }

  return errors;
};

export default validate;