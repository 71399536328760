import React from 'react';
import { Container } from '@material-ui/core';
import cls from './contacts.module.scss';
import maps from '../../img/interdocs.jpg';
import fone from '../../img/fone.png';
// import mts from '../../img/mts.png';
// import vel from '../../img/vel.png';
// import viber from '../../img/viber.jpg';
import HelmetComponent from '../../components/HelmetComponent';
import { useTranslation } from 'react-i18next';

const Contacts = ({language}) => {
    const { t } = useTranslation();
    return (
        <Container component="main" className={cls.container}>
            <HelmetComponent titlePage={t('helmet.сontacts.title',{ lng: language })} description={t('helmet.сontacts.description',{ lng: language })} title={t('helmet.сontacts.title',{ lng: language })} keywords={t('helmet.сontacts.keywords',{ lng: language })} />
            <div className={cls.contacts_home_name}>
                <h1>{t('pages.Contacts.h2',{ lng: language })}</h1>
            </div>
            <div className={cls.contacts_content_wrapper}>
                <div className={cls.contacts_information_block}>
                    <div className={cls.contacts_current_item}>
                        <h2>SRL "LAURATUR"</h2>
                        <span>мун.Бэлць, ул. Дечебал 14А/1</span>
                        <span>Фискальный код 1018602007555</span>
                        <span>Код IBAN: MD88EX0000002251769097MD</span>
                        <span>B.C. "EXIMBANK" SA, филиал №18</span>
                        <span>BIC: EXMMMD22427</span>
                    </div>
                    <div className={cls.contacts_current_item}>
                        <h5>{t('pages.Contacts.description1',{ lng: language })}</h5>
                        <span className={cls.contacts_block_call}>
                            <a href="tel:+373 671 40415">
                                <img src={fone} alt='номер телефона' /> +373 671 40415
                        </a>
                        </span>
                        <span className={cls.contacts_block_call}>
                            <a href="tel:+373 698 29829">
                                <img src={fone} alt='номер телефона' />+373 698 29829
                        </a>
                        </span>
                        <span className={cls.contacts_block_call}>
                            <a href="tel:+373 693 49200">
                                <img src={fone} alt='номер телефона' />+373 693 49200
                        </a>
                        </span>
                        <span className={cls.contacts_block_call}>
                            <a href="tel:+373 231 92328">
                                <img src={fone} alt='номер телефона' />+373 231 92328
                              </a>
                        </span>
                    </div>
                    <div className={cls.contacts_current_item}>
                        <h5> e-mail:</h5>
                        <span className={cls.contacts_block_call}>  <a href="mailto:bdtinterdoc@gmail.com">bdtinterdoc@gmail.com</a></span>
                        <span className={cls.contacts_block_call}> <a href="mailto:lauraturmoldova@gmail.com">lauraturmoldova@gmail.com</a></span>
                        <span className={cls.contacts_block_call}>  <a href="mailto:lauraturmoldova@mail.ru">lauraturmoldova@mail.ru</a></span>
                    </div>

                </div>
                <div className={cls.contacts_img_block}>
                    <img src={maps} alt='карта' />
                </div>

            </div>
        </Container>
    )
}

export default Contacts;