import React from 'react';
import cls from './information.module.scss';
import { Card, Container } from '@material-ui/core';
import HelmetComponent from '../../components/HelmetComponent';
import { useTranslation } from 'react-i18next';
import CompanyConsulting from '../../components/CompanyConsulting';

const Consulting = ({language}) => {
    const { t } = useTranslation();
    return (
        <main className={cls.information_container}>
            <HelmetComponent titlePage={t('helmet.consulting.title',{ lng: language })} description={t('helmet.consulting.description',{ lng: language })} title={t('helmet.consulting.title',{ lng: language })} keywords={t('helmet.consulting.keywords',{ lng: language })} />
            <div style={{ position: 'relative' }}>
                <Container component="main" maxWidth="lg">
                    {/* </Container> */}
                    <Card className={cls.information_block_card}>
                        <CompanyConsulting language={language}/>
                    </Card>
                </Container>
            </div>
        </main>)
};

export default Consulting;