import React, { useState } from 'react';
import cls from './login.module.scss'
import {
    CssBaseline,
    CircularProgress,
    Card,
} from '@material-ui/core';
import { useFormik } from 'formik';
import InputForm from '../../components/form/InputForm';
import validate from './component/validate'
import ButtonStyle from '../../components/ButtonStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


const Login = ({language}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { values, handleSubmit, handleChange, handleBlur, errors, touched } = useFormik({
        initialValues: {
            number: '',
            service: 'отсутствует',
            name: '',
            comment: ''
        },
        validate: (values) => validate(values, t),
        onSubmit: (values) => {
            setLoading(true);
            fetch('mail.php', {
                method: "POST", headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }, body: JSON.stringify(values)
            }).then(() => {
                setLoading(false);
                toast.success(t('errorChange.true',language ?{ lng: language }:''))
            },
                error => {
                    setLoading(false);
                    toast.error(t('errorChange.false',language ?{ lng: language }:''))
                })
        },
    });
    return (
        <Card className={cls.login_block_card}>
            <CssBaseline />
            <div className={cls.login_block_content}>
                <h2 >
                    {t('Login.h2',language ?{ lng: language }:'')}
                </h2>
                <form onSubmit={handleSubmit}>
                    <InputForm
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        fullWidth
                        values={values}
                        name="name"
                        autoComplete="name"
                        label={t('Login.name',language ?{ lng: language }:'')}
                        touched={touched}
                        errors={errors}
                        disabled={loading}
                        size='small'
                    />
                    <div style={{ marginTop: 15 }}>
                        <InputForm
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            fullWidth
                            values={values}
                            name="number"
                            autoComplete="number"
                            label={t('Login.number',language ?{ lng: language }:'')}
                            touched={touched}
                            errors={errors}
                            disabled={loading}
                            size='small'
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <InputForm
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            fullWidth
                            select
                            values={values}
                            name="service"
                            label={t('Login.service',language ?{ lng: language }:'')}
                            touched={touched}
                            errors={errors}
                            disabled={loading}
                            menuItemArr={[
                                { name: t('Login.menuItemArr.notarial',language ?{ lng: language }:''), value: 'Нотариальный перевод' },
                                { name: t('Login.menuItemArr.writing',language ?{ lng: language }:''), value: 'Письменный перевод' },
                                { name: t('Login.menuItemArr.oral',language ?{ lng: language }:''), value: 'Устный перевод' },
                                { name: t('Login.menuItemArr.apostille',language ?{ lng: language }:''), value: 'Апостилирование' },
                                { name: t('Login.menuItemArr.legalization',language ?{ lng: language }:''), value: 'Легализация' },
                                { name: t('Login.menuItemArr.nostrification',language ?{ lng: language }:''), value: 'Нострификация' },
                            ]}
                            defaultMenuItem={{ name: '-', value: 'отсутствует' }}
                            size='small'
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <InputForm
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            fullWidth
                            values={values}
                            name="comment"
                            autoComplete="comment"
                            label={t('Login.comment',language ?{ lng: language }:'')}
                            touched={touched}
                            errors={errors}
                            disabled={loading}
                            size='small'
                            type='textarea'
                            multiline
                            rows="4"
                        />
                    </div>
                    <div className={cls.login_button_wraper}>
                        <ButtonStyle type="submit" disabled={loading}>{t('Login.send',language ?{ lng: language }:'')}</ButtonStyle>
                        {loading && <CircularProgress size={24} className={cls.login_loading} />}
                    </div>
                </form>
            </div>
        </Card>
    )
}

export default Login;