import React from 'react';
import cls from './inform.module.scss';
import { School, Mic, BusinessCenter, Public } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const InformBlock = ({ language }) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('InformBlock.h2', { lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.cur_block}>
                        <School fontSize="small" />
                        <div className={cls.text_wrapper}>
                            <h4>
                                {t('InformBlock.notarized.h4', { lng: language })}
                            </h4>
                            <div>
                                {t('InformBlock.notarized.div', { lng: language })}
                            </div>
                        </div>
                    </div>
                    <div className={cls.cur_block}>
                        <Mic fontSize="small" />
                        <div className={cls.text_wrapper}>
                            <h4>
                                {t('InformBlock.oral.h4', { lng: language })}
                            </h4>
                            <div>
                                {t('InformBlock.oral.div', { lng: language })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${cls.block_double} ${cls.top}`}>
                    <div className={cls.cur_block}>
                        <BusinessCenter fontSize="small" />
                        <div className={cls.text_wrapper}>
                            <h4>
                                {t('InformBlock.legalization.h4', { lng: language })}
                            </h4>
                            <div>
                                {t('InformBlock.legalization.div', { lng: language })}
                            </div>
                        </div>
                    </div>
                    <div className={cls.cur_block}>
                        <Public fontSize="small" />
                        <div className={cls.text_wrapper}>
                            <h4>
                                {t('InformBlock.apostille.h4', { lng: language })}
                            </h4>
                            <div>
                                {t('InformBlock.apostille.div', { lng: language })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InformBlock;