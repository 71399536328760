import React from 'react';
import {
    useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const HelmetComponent = ({ titlePage, description, title, keywords }) => {
    const location = useLocation();
    const langAlternative = [];
    // eslint-disable-next-line no-restricted-syntax
    // for (const [key, value] of Object.entries(localeHelper.locales)) {
    //   langAlternative.push(
    //     <link
    //       key={key}
    //       rel="alternate"
    //       hrefLang={key}
    //       href={`https://${value.domain}${location.pathname}`}
    //     />,
    //   );
    // }

    return (
        <Helmet titleTemplate="%s">
            <title>{titlePage}</title>
            <meta name="description" content={description} />
            <meta name="theme-color" content="#1867db" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="INTERDOC" />
            <meta name="keywords" content={keywords} />
            <meta
                property="og:url"
                content={location.pathname}
            />
            {/* <meta
          property="og:image"
          content="Chicken"
        /> */}
            {/* <meta property="fb:app_id" content={process.env.REACT_APP_FB_APP} /> */}
            <link
                rel="canonical"
                href={location.pathname}
            />
            {langAlternative}
        </Helmet>
    )
}


HelmetComponent.propTypes = {
    titlePage: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string
};

HelmetComponent.defaultProps = {
    titlePage: '',
    description: '',
    title: '',
    keywords: ''
};

export default HelmetComponent
