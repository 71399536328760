import React from 'react';
import cls from './maps.module.scss';
import { Card } from '@material-ui/core';
import interdocs from '../../img/interdocs.jpg';

const Maps = () => {
    return (
        <Card className={cls.info_container_wrapper}>
            <img src={interdocs} alt='карта' />
        </Card>
    )
}
export default Maps;