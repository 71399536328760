import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';

const CompanyRelocation = ({language}) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('pages.CompanyRelocation.h2',{ lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <div>{t('pages.CompanyRelocation.description1',{ lng: language })}</div>
                        <div><a href="http://moldova.mid.ru/" target="blank"> http://moldova.mid.ru/</a></div>
                        <div><a href="http://moldova.mid.ru/112" target="blank">  http://moldova.mid.ru/112</a></div>
                        <div>{t('pages.CompanyRelocation.description2',{ lng: language })}</div>
                        {/* <div>{t('pages.CompanyRelocation.description3',{ lng: language })}</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyRelocation;