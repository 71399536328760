import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';
import fone from '../../img/fone.png';
import { Link } from 'react-router-dom';

const CompanyConsulting = ({ language }) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('pages.CompanyConsulting.h2', { lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <div>{t('pages.CompanyConsulting.description1', { lng: language })}</div>
                        <div>{t('pages.CompanyConsulting.description2', { lng: language })}
                            <Link className={cls.link} to={`/${language}/confession`}>
                                {t('pages.CompanyConsulting.description3', { lng: language })}
                            </Link>
                            {t('pages.CompanyConsulting.description4', { lng: language })}
                        </div>
                        <div>{t('pages.CompanyConsulting.description5', { lng: language })}</div>
                        <div>{t('pages.CompanyConsulting.description6', { lng: language })}</div>
                        <h4>
                            <a href="tel:+373 231 92328">
                                <img src={fone} alt='номер телефона' /> +373 231 92328
                        </a>
                        </h4>
                        <h4>{t('pages.CompanyConsulting.description7', { lng: language })}</h4>
                        <h4>
                            <a href="tel:+373 671 40415">
                                <img src={fone} alt='номер телефона' /> +373 671 40415
                        </a>
                        </h4>
                        <h4>
                            <a href="tel:+373 698 29829">
                                <img src={fone} alt='номер телефона' /> +373 698 29829
                        </a>
                        </h4>
                        <h4>
                            <a href="tel:+373 693 49200">
                                <img src={fone} alt='номер телефона' /> +373 693 49200
                        </a>
                        </h4>
                        <h4>{t('pages.CompanyConsulting.description8', { lng: language })}<span></span> {' '} <a href="mailto:bdtinterdoc@gmail.com">bdtinterdoc@gmail.com</a>, <a href="mailto:interdoc@mail.ru">interdoc@mail.ru</a>, skype: oxanaoxana1470</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyConsulting;