import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './locales';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { hydrate, render } from 'react-dom';


// Grab the state from a global variable injected into the server-generated HTML
// eslint-disable-next-line no-underscore-dangle

// Allow the passed state to be garbage-collected
// eslint-disable-next-line no-underscore-dangle

const rootElement = document.getElementById('root');
const renderElement = (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <HelmetProvider >
      <ToastContainer />
      <App />
    </HelmetProvider>
  </BrowserRouter>)
// let method = 'render';
if (rootElement.hasChildNodes()) {
  console.log('App: Hydrate');
  hydrate(renderElement, rootElement);
} else {
  console.log('App: Render');
  render(renderElement, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();