import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';

const CompanyMail = ({language}) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('pages.CompanyMail.h2',{ lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <div>
                            {t('pages.CompanyMail.description1',{ lng: language })}
                            <a href="http://www.ups.md/" target="blank">"UPS"</a>
                            {t('pages.CompanyMail.description2',{ lng: language })}
                            <a href="http://www.ksd.kiev.ua/" target="blank">КСД.</a>
                            {t('pages.CompanyMail.description3',{ lng: language })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyMail;