import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core';
import cls from './header.module.scss';
import logo from '../../img/logo_interdoc.png';
import { Link, NavLink } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';
import swipeHandler from './swipeHandler';
import { useTranslation } from 'react-i18next';
import HamburgerMenu from '../../components/HamburgerMenu';
import lngUrl from '../../helpers/lngUrl';

const Header = ({ buttonBurger, setButtonBurger }) => {
    const { t, i18n: { language } } = useTranslation();
    useEffect(() => {
        swipeHandler(setButtonBurger)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const arrayMenu = [{ name: t('Header.home', { lng: lngUrl() }), path: `/${language}` },
    { name: t('Header.translations', { lng: lngUrl() }), path: `/${language}/translations` },
    { name: t('Header.consulting', { lng: lngUrl() }), path: `/${language}/consulting` },
    { name: t('Header.courier', { lng: lngUrl() }), path: `/${language}/mail` },
    { name: t('Header.relocation', { lng: lngUrl() }), path: `/${language}/relocation` },
    { name: t('Header.contacts', { lng: lngUrl() }), path: `/${language}/contacts` }];
    const [styleLeft, setStyleLeft] = useState({ left: 0, width: 0 });

    const handleMouseEnter = (e) => {
        setStyleLeft({ left: e.target.offsetLeft, width: e.target.offsetWidth });
    }

    const menuContent = <>
        {arrayMenu.map((el, index) => (
            <div key={index} className={cls.header_tab} onMouseEnter={handleMouseEnter} onClick={() => {
                setButtonBurger(false);
            }}>
                <NavLink onClick={() => window.scrollTo({ top: 0 })} exact className={cls.header_tab_all} activeClassName={cls.header_tab_active} to={el.path}>
                    {el.name}
                </NavLink>
            </div>
        ))}
    </>
    return (<>
        <header className={cls.header_container}>
            <div style={{ display: 'none' }}>
                {['/ru', 
                '/ru/translations', 
                '/ru/consulting', 
                '/ru/mail', 
                '/ru/relocation', 
                '/ru/contacts', 
                '/ru/confession', 
                '/en', 
                '/en/translations', 
                '/en/consulting', 
                '/en/mail', 
                '/en/relocation', 
                '/en/contacts', 
                '/en/confession', 
                '/md', 
                '/md/translations', 
                '/md/consulting', 
                '/md/mail', 
                '/md/relocation', 
                '/md/contacts', 
                '/md/confession',]
                    .map(el => <Link key={el + '22'} to={el}>
                        {el}
                    </Link>)}
            </div>
            <Container component="main" className={cls.container}>
                <div className={cls.header_logo_container}>
                    <Link to={`/${language}`} onClick={() => setButtonBurger(false)}>
                        <img src={logo} alt='logo' />
                    </Link>
                </div>
                <div className={cls.header_button_burger}>
                    <div className={cls.menu_wrapper} onClick={() => setButtonBurger(!buttonBurger)}>
                        <HamburgerMenu boolean={buttonBurger} />
                    </div>
                </div>
                <div onMouseLeave={() => setStyleLeft({ ...styleLeft, width: 0 })} className={`${cls.header_tabs_container}`}>
                    {menuContent}
                    <span className={cls.header_line} style={styleLeft} />
                </div>
            </Container>
        </header>
        <div className={cls.menu_conatiner_wrapper}>
            <div id="outer-container">
                <Menu outerContainerId={"outer-container"} onStateChange={({ isOpen }) => {
                    setButtonBurger(isOpen);
                }} right isOpen={buttonBurger} customBurgerIcon={false}  >
                    {menuContent}
                </Menu>
            </div>
        </div>
    </>)
}

export default Header;