import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';

const Company = ({language}) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('Company.h2',{ lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <h4>
                            {t('Company.description1',{ lng: language })}
                        </h4>
                        <div>
                            {t('Company.description2',{ lng: language })}
                            <li> {t('Company.description3',{ lng: language })}</li>
                            <li> {t('Company.description4',{ lng: language })}</li>
                            <li> {t('Company.description5',{ lng: language })}</li>
                        </div>
                        <div>
                            {t('Company.description6',{ lng: language })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Company;