import React from 'react';
import cls from './company.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const CompanyConfession = ({ language }) => {
    const { t } = useTranslation();
    return (
        <div className={cls.info_container_wrapper}>
            <h2>{t('pages.CompanyConfession.h2', { lng: language })}</h2>
            <div className={cls.wrapper_info_block}>
                <div className={cls.block_double}>
                    <div className={cls.text_wrapper}>
                        <div>
                            {t('pages.CompanyConfession.description1', { lng: language })}
                        </div>
                        <h4>
                            <span>{t('pages.CompanyConfession.description2', { lng: language })}</span>
                            {t('pages.CompanyConfession.description3', { lng: language })}
                            <span>{t('pages.CompanyConfession.description4', { lng: language })}</span>
                        </h4>
                        <div>{t('pages.CompanyConfession.description5', { lng: language })}</div>
                        <li>
                            {t('pages.CompanyConfession.description6', { lng: language })}
                        </li>
                        <h4>{t('pages.CompanyConfession.description7', { lng: language })}</h4>
                    </div>
                </div>
                <div className={cls.back}>
                    <NavLink to={`/${language}/consulting`}>{t('pages.CompanyConfession.description8', { lng: language })}</NavLink>
                </div>
            </div>
        </div>
    )
}
export default CompanyConfession;